import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
export default [
  {
    name: "index___cs",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  }
]