import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import performance_client_OXWUsvTdFu from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-bugsnag/dist/runtime/client/performance.client.js";
import plugin_adVF2uRka6 from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_A297C4CfS8 from "/opt/atlassian/pipelines/agent/build/node_modules/@ambitiondev/nuxt-cookiebot/dist/runtime/plugin.js";
import plugin_tbFNToZNim from "/opt/atlassian/pipelines/agent/build/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import gsapPlugin_HlwIIyl85L from "/opt/atlassian/pipelines/agent/build/.nuxt/gsapPlugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import clickOutside_C8jIyDRU4O from "/opt/atlassian/pipelines/agent/build/plugins/clickOutside.js";
import event_bus_S6Iq1iGe5d from "/opt/atlassian/pipelines/agent/build/plugins/event-bus.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  performance_client_OXWUsvTdFu,
  plugin_adVF2uRka6,
  plugin_A297C4CfS8,
  plugin_tbFNToZNim,
  gsapPlugin_HlwIIyl85L,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  clickOutside_C8jIyDRU4O,
  event_bus_S6Iq1iGe5d
]